import { render, staticRenderFns } from "./consignacaoForm.vue?vue&type=template&id=850dc15c&scoped=true"
import script from "./consignacaoForm.vue?vue&type=script&lang=js"
export * from "./consignacaoForm.vue?vue&type=script&lang=js"
import style0 from "./consignacaoForm.vue?vue&type=style&index=0&id=850dc15c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "850dc15c",
  null
  
)

export default component.exports